import { IEnvironment } from './environment';

export const environmentProd: IEnvironment = {
    environments: [
        {
            name: 'Mainnet',
            url: 'https://api.ergo.aap.cornell.edu',
        },
    ],
    isLoggerEnabled: false,
};
